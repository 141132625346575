import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website integration - WS stream for WQHD cameras",
  "path": "/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/",
  "dateChanged": "2022-12-08",
  "author": "Mike Polinowski",
  "excerpt": "Insert the Websocket HTML5 live video from your WQHD 2k+ camera into your website.",
  "image": "./AU_SearchThumb_WebsiteIntegration_HD_Cams.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website integration - WS stream for WQHD cameras' dateChanged='2022-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Insert the Websocket HTML5 live video from your WQHD 2k+ camera into your website.' image='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationDE={props.location.pathname} locationEN='/en/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/' locationFR='/fr/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/' crumbLabel="Website" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "website-integration---ws-stream-for-wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#website-integration---ws-stream-for-wqhd-cameras",
        "aria-label": "website integration   ws stream for wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website integration - WS stream for WQHD cameras`}</h1>
    <blockquote>
      <p parentName="blockquote">{`This manual is `}<strong parentName="p">{`only intended for 1080p Full HD camera models`}</strong>{`. For `}<strong parentName="p">{`1080p Full HD camera models`}</strong>{` please follow our guide `}<a parentName="p" {...{
          "href": "/en/Advanced_User/Website_Integration/HTML5_Stream_Full_HD_Cameras/"
        }}>{`here`}</a>{`. For `}<strong parentName="p">{`720p HD models`}</strong>{` please see the HD guide `}<a parentName="p" {...{
          "href": "/en/Advanced_User/Website_Integration/HD_Camera_Integration/"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>
    <p><strong parentName="p">{`See also`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/Websocket_Stream_Proxy/"
        }}>{`How to use a Webproxy with the Websocket Video Stream`}</a></li>
    </ul>
    <h2 {...{
      "id": "ws-video-stream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ws-video-stream",
        "aria-label": "ws video stream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WS Video Stream`}</h2>
    <p>{`With your INSTAR WQHD cameras you can integrate the HTML5 (plugin-free) websocket video stream directly into a website. We have provided you with the required HTML and JavaScript code in our `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Developer/html_ws_client.zip"
      }}>{`download area`}</a>{`.`}</p>
    <p>{`To test this code:`}</p>
    <ol>
      <li parentName="ol">{`Simply drag the HTML file and the folder with scripts into the active directory of your web server.`}</li>
      <li parentName="ol">{`rename the HTML file to `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`index.html`}</code>{` and open it in your text or code editor`}</li>
      <li parentName="ol">{`specify in the file the address of your camera, the HTTP port, your camera login and the desired stream:`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3fefb07684109baa034edddedc2a7887/966a0/INSTAR_WS_Video_Stream.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "20.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAAyUlEQVQI1z3LW2+CMBiAYW2pOuhXDqWllWBKrSecjA6VTBdv/P//aWEm3rwXb/JMNGtoeYbVjeQthg1i2zA5FuWN6zvXg1w98uUdeA/8DJmX6qL1T5g0iNYY7IRQm1W+bp/SDpE4ztIdAhuneyH6Ul2k6ufminWHixNKNtPITCODYJQjXlAn9MntfoulF6rjsp0zR+m+Yt8u+cqhIXYg6jNItxhsAOuArV9yxB/gxvVfTG1ALQEbw0HHncu8idtZfSWVx6F5mzf+A6ziFvLU6m6TAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3fefb07684109baa034edddedc2a7887/e4706/INSTAR_WS_Video_Stream.avif 230w", "/en/static/3fefb07684109baa034edddedc2a7887/d1af7/INSTAR_WS_Video_Stream.avif 460w", "/en/static/3fefb07684109baa034edddedc2a7887/7f308/INSTAR_WS_Video_Stream.avif 920w", "/en/static/3fefb07684109baa034edddedc2a7887/81785/INSTAR_WS_Video_Stream.avif 944w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3fefb07684109baa034edddedc2a7887/a0b58/INSTAR_WS_Video_Stream.webp 230w", "/en/static/3fefb07684109baa034edddedc2a7887/bc10c/INSTAR_WS_Video_Stream.webp 460w", "/en/static/3fefb07684109baa034edddedc2a7887/966d8/INSTAR_WS_Video_Stream.webp 920w", "/en/static/3fefb07684109baa034edddedc2a7887/59b61/INSTAR_WS_Video_Stream.webp 944w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3fefb07684109baa034edddedc2a7887/81c8e/INSTAR_WS_Video_Stream.png 230w", "/en/static/3fefb07684109baa034edddedc2a7887/08a84/INSTAR_WS_Video_Stream.png 460w", "/en/static/3fefb07684109baa034edddedc2a7887/c0255/INSTAR_WS_Video_Stream.png 920w", "/en/static/3fefb07684109baa034edddedc2a7887/966a0/INSTAR_WS_Video_Stream.png 944w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3fefb07684109baa034edddedc2a7887/c0255/INSTAR_WS_Video_Stream.png",
              "alt": "INSTAR Kamera Websocket Videostream",
              "title": "INSTAR Kamera Websocket Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ws_protocol`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ws`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`wss`}</code>{` `}{`*`}</li>
      <li parentName="ul"><strong parentName="li">{`ws_hostname`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`192.168.2.120`}</code>{` (DDNS or local IP)`}</li>
      <li parentName="ul"><strong parentName="li">{`ws_port`}</strong>{`     = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`80`}</code>{` (Camera HTTP or HTTPS Port) `}{`*`}</li>
      <li parentName="ul"><strong parentName="li">{`ws_endpoint`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`/ws`}</code>{` (The URL where the ws stream is served)`}</li>
      <li parentName="ul"><strong parentName="li">{`cam_username`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`admin`}</code>{` (User name for the camera Login)`}</li>
      <li parentName="ul"><strong parentName="li">{`cam_password`}</strong>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`instar`}</code>{` (User login for the camera Login)`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`*`}{` Note`}</strong>{`: When using TLS (secure websocket) you will have to use a CA signed certificate like from Let's Encrypt. Follow the link at the bottom of this article to a Github repository that helps you to setup a NGINX Docker container you can use to handle the encryption part for you.`}</p>
    </blockquote>
    <p>{`Google Chrome is very strict when it comes to MIME Types (Firefox mostly does not care - it is best to start testing in Firefox and then try to get the stream to work in Chrome...). Depending on your settings you will have to adjust them here:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`0`}</code>{` = h.264 video & audio`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`1`}</code>{` = h.264 video & NO audio`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` = h.265 video & audio`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`3`}</code>{` = h.265 video & NO audio`}</li>
    </ul>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Add media mime type`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 0 => 'video/mp4; codecs="avc1.4D001E, mp4a.40.2"'`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 1 => 'video/mp4; codecs="avc1.4D001E"'`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 2 => 'video/mp4; codecs="hev1.2.4.L120.B0, mp4a.40.2"'`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 3 => 'video/mp4; codecs="hev1.2.4.L120.B0"'`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` codec_mime `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Then start your web server and open the provided page in your web browser. The code you downloaded above is a minimal example. For a more complete version check:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/546e25788353a771d9006c5775a37caa/e996b/ws_html_video_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "92.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAIAAAAf7rriAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAEMUlEQVQ4y3WOW0xadxzH/2W2q4pTK4pcD+ccQC4CBREUUUFQKoh3RdB62dC5SZWL66pd24emybbsYVuyLWm2pF1s9rLaOiugtnFNmi0ma5p0tybdQ5c0W6dOAV2BczicBZcte/GTb/LL75t88/2CPqerTCRGBKISJsTkwgwOTGfx/hVUyuYxOHAp+z+HV8KCEHut9BU7XFUBlldWby4shJZXloKh+RsL1+dvLgVDwVA4GAqHwitfLd6av7GwuBQMhZf/MZdC4fDt26tfr63cuQM2NjZ3dnbIfeKJRCKRJP/H7u7u1taf5AGAWCy2vb2NYRhBEPF4IpnEcDyFYTiG4QRBRGLRnWgkiWPbkcjzeCKRTMb3lUgmU6kUiEQi0WiMIAgind7dex7b3cMxPIXhBJ4iSTKNESROHNi8ubkVjcXSJEkQRAqPk2Q6TZKZN3PJ7578vPDgbuiHb1d+XF97dH/1p/Xg99/cenhv7dH9FJHKhH/7/VkmmUr/8gx7/Aex9/jJXw8exn99SpLkJ3evOy6fHbl60X31kueL98bn3h65crH/s/Nn5j9K4hiIZ0iQJJnAybl18vI98unHcxuBc9EvFzPL9vsPnG3t6up0udocjg6nw3nSMTDocL484BwdHnIPDQ0POAedPf293a7urr7O9p721u52W6fd2mE3Wy3vf/gByGYwihCkGIZLELgURVgoAqEwwodRAcoXlXEFfFhahpaLmTwui8ctYjEKGKUF9JLD1NxxjwfQhCJ2ubxULGFKJGyJhCeRKlRKfaVmpNGsUVeiUplYW1ljMthOGGVqJR1GmTyExuZSacUTXi+gyeRCjVZWVSWsOC6vVFoaa0wGbY/ZeGVsWN9gkTRayxuMU+6+6cAoXaNjIHxYpWRKRfm0Iu+0HzBrdOUmo9FmkmrVHLmcW16u0GmVWrXVpLd0tNb2OVQWs3uoy3myGamrqjfUN/e0l9UpYQQOnH4D2HstjW2mnl6LrUlX22yutTWpq9TqmsrWlrq29kZji6G1u8HaojfWq7qbtZM9xv6B5karfsyouzB7BkBCRCSXNpmqzQ0VXRZNX2utXiPqM9V4XXaBXlthNfS6TuiMKraIL1UrZFK+WCoQiARiiHN25k1wlM5gC4VKlVyh4MtkqOJ4mUQMOeuq3x1oGWypNhjVKpvBVKeo08vyReJ8JjuPzqAW0ynUl8Y8p0ABi8VEUb5YxBcLICHKEfB5AkQlFWkVErVKAguRAphbyuNwUYjB59MhXgkMF0NQYXHJKa8PgKys7MJjLA6TSjv2YmF+TlEhJS9XiUBmiRAAQMk6Ao7mgBeOAMphkJ1zKIdKyc2j5FABODTsHgXuCc9kIHDurZmpgH8q4Pednp7wed+Znf300nm/f/LVySnf2ZnXvN7R1z3jPr/HHxif8np9fo/H8/m1a38DLCwU0KMg9xYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/546e25788353a771d9006c5775a37caa/e4706/ws_html_video_01.avif 230w", "/en/static/546e25788353a771d9006c5775a37caa/d1af7/ws_html_video_01.avif 460w", "/en/static/546e25788353a771d9006c5775a37caa/7f308/ws_html_video_01.avif 920w", "/en/static/546e25788353a771d9006c5775a37caa/35372/ws_html_video_01.avif 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/546e25788353a771d9006c5775a37caa/a0b58/ws_html_video_01.webp 230w", "/en/static/546e25788353a771d9006c5775a37caa/bc10c/ws_html_video_01.webp 460w", "/en/static/546e25788353a771d9006c5775a37caa/966d8/ws_html_video_01.webp 920w", "/en/static/546e25788353a771d9006c5775a37caa/c139f/ws_html_video_01.webp 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/546e25788353a771d9006c5775a37caa/81c8e/ws_html_video_01.png 230w", "/en/static/546e25788353a771d9006c5775a37caa/08a84/ws_html_video_01.png 460w", "/en/static/546e25788353a771d9006c5775a37caa/c0255/ws_html_video_01.png 920w", "/en/static/546e25788353a771d9006c5775a37caa/e996b/ws_html_video_01.png 1050w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/546e25788353a771d9006c5775a37caa/c0255/ws_html_video_01.png",
              "alt": "INSTAR Kamera Websocket Videostream",
              "title": "INSTAR Kamera Websocket Videostream",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://forum.instar.com/t/2k-wqhd-websocket-videostream-fuer-webseiteneinbindung/29077"
        }}>{`2k+ WQHD Websocket Videostream für Webseiteneinbindung`}</a>{` (`}<em parentName="li">{`Github Links`}</em>{`)`}</li>
    </ul>
    <p><em parentName="p">{`The Forum entry also contains a link to a repository for an NGINX web proxy you can use to expose your camera's video stream to the internet and handle the encryption using an e.g. Let's Encrypt certificate`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      